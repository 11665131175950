import React from 'react';
import * as styles from './SheetsHeader.module.scss';
import * as common from 'styles/common.module.scss';
import clsx from "clsx";
import {Link} from "gatsby";


const SheetsHeader = ({ subjects }) => {

  return (
    <div className={clsx(common.container, styles.wrapper)}>
      <nav className={styles.nav}>
        {Object.values(subjects).sort((a, b) => (a.order > b.order) ? 1 : -1).map(({ name, slug }) =>
          <Link activeClassName={styles.active} to={`/arkusze/${slug}`} key={slug} className={styles.link}>
            {name}
          </Link>
        )}
      </nav>
    </div>
  );
}

SheetsHeader.propTypes = {};

export default SheetsHeader;
