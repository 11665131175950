// extracted by mini-css-extract-plugin
export var hideBelowXl = "CookiesInfo-module--hideBelowXl--muQll";
export var hideOverXl = "CookiesInfo-module--hideOverXl--vZSqO";
export var hideBelowL = "CookiesInfo-module--hideBelowL--6a21R";
export var hideOverL = "CookiesInfo-module--hideOverL--DCp92";
export var hideBelowM = "CookiesInfo-module--hideBelowM--QjohX";
export var hideOverM = "CookiesInfo-module--hideOverM--V7MIM";
export var hideBelowS = "CookiesInfo-module--hideBelowS--t8GJw";
export var hideOverS = "CookiesInfo-module--hideOverS--i9k9f";
export var hideBelowXs = "CookiesInfo-module--hideBelowXs--ZAC0p";
export var hideOverXs = "CookiesInfo-module--hideOverXs--G7rnC";
export var wrapper = "CookiesInfo-module--wrapper--M16B8";
export var slideIn = "CookiesInfo-module--slideIn--f8jud";
export var content = "CookiesInfo-module--content--z6S4g";
export var button = "CookiesInfo-module--button--4K0mV";