import React from 'react';

import PropTypes from 'prop-types';

import * as styles from './CookiesInfo.module.scss';

const CookiesInfo = ({ onClose }) => (
  <div className={styles.wrapper}>
    <p className={styles.content}>
      Ta strona używa ciasteczek w&nbsp;celach marketingowych oraz aby zapewnić najwyższy poziom świadczonych usług. Więcej&nbsp;w&nbsp;
      <a href='https://app.mojamatura.edu.pl/documents/privacy_policy' rel='noopener' target='_blank'>
        polityce&nbsp;prywatności
      </a>
    </p>
    <button className={styles.button} onClick={onClose} type='button'>
      Rozumiem
    </button>
  </div>
);

CookiesInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CookiesInfo;
