import React  from 'react';

import PropTypes from 'prop-types';
import * as styles from './SheetsLayout.module.scss';
import * as common from 'styles/common.module.scss';
import SheetsHeader from "./_components/SheetsHeader";
import clsx from "clsx";

const SheetsLayout = ({ children, pageContext }) => {
  return (
    <div className={clsx(common.container, styles.wrapper)}>
      <SheetsHeader subjects={pageContext.subjects}  />
      {children}
    </div>
  );
};

SheetsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SheetsLayout;
