import React from 'react';

import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import EnvelopeIcon from 'images/svg/envelope.svg';
import * as common from 'styles/common.module.scss';

import * as styles from './Footer.module.scss';

const query = graphql`
  query FooterQuery {
    strapiFooter {
      email
      text
    }
  }
`;

const Footer = () => {
  const { strapiFooter } = useStaticQuery(query);

  return (
    <footer className={styles.footer}>
      <div className={clsx(common.container, styles.footerContainer)}>
        <div className={styles.footerLeft}>
          <div className={styles.copyright}>
            <OutboundLink href={`mailto:${strapiFooter.email}`} rel='noreferrer' target='_blank'>
              <EnvelopeIcon className={styles.envelopeIcon} />
              {strapiFooter.email}
            </OutboundLink>
            <span>
              © {new Date().getFullYear()} {strapiFooter.text}
            </span>
          </div>
        </div>
        <div className={styles.footerRight}>
          <div className={styles.links}>
            <OutboundLink className={styles.link} href='https://app.mojamatura.edu.pl/documents/terms' rel='noopener' target='_blank'>
              Regulamin
            </OutboundLink>
            <OutboundLink
              className={styles.link}
              href='https://app.mojamatura.edu.pl/documents/privacy_policy'
              rel='noopener'
              target='_blank'
            >
              Polityka prywatności
            </OutboundLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
