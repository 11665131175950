const createOutboundLinkHref = base => {
  if(typeof window === "undefined") return;
  const source = window.SOURCE
  const sourceId = window.SOURCE_ID
  const data = {source: source};
  if (sourceId) data.sourceId = sourceId
  const searchParams = new URLSearchParams(data);
  return `${base}/?${searchParams.toString()}`
}

export default createOutboundLinkHref
