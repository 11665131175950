import React, {useEffect, useState} from 'react';

import clsx from 'clsx';
import {OutboundLink, trackCustomEvent} from 'gatsby-plugin-google-analytics';

import GA_EVENTS from 'constants/ga_events';
import LogoFull from 'images/svg/logo_full.svg';
import CloseIcon from 'images/svg/close.svg';
import MenuIcon from 'images/svg/menu.svg';
import * as common from 'styles/common.module.scss';
import * as mq from 'styles/mq.module.scss';
import isBrowser from 'utils/isBrowser';

import * as styles from './Header.module.scss';
import {Link} from "gatsby";
import createOutboundLinkHref from "utils/createOutboundLinkHref";

const Header = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const openMenu = () => setIsMenuOpen(true)
    const closeMenu = () => setIsMenuOpen(false)
    const priceListClick = () => {
        closeMenu()
        trackCustomEvent({
            category: GA_EVENTS.INTERNAL_ROUTING,
            action: 'header price list click',
        });
    };


    const schoolClick = () => {
        closeMenu()
        trackCustomEvent({
            category: GA_EVENTS.INTERNAL_ROUTING,
            action: 'header schools click',
        });
    };

    return (
        <header className={styles.header}>

            <nav className={clsx(styles.mobileNav, mq.hideOverS, isMenuOpen && styles.mobileNavOpen)}>
                <button onClick={closeMenu} className={clsx(styles.iconButton, styles.closeButton)}><CloseIcon /></button>
                <Link className={styles.headerLinkInMobileNav} to='/#szkoły' onClick={schoolClick}
                      type='button'>
                    Dla szkół
                </Link>
                <Link className={styles.headerLinkInMobileNav} to='/#cennik' onClick={priceListClick}
                      type='button'>
                    Cennik
                </Link>
                <OutboundLink
                    onClick={closeMenu}
                    className={styles.headerLinkInMobileNav}
                    href={createOutboundLinkHref('https://app.mojamatura.edu.pl/auth/register')}
                    rel='noopener'
                    target='_blank'
                >
                    Rejestracja
                </OutboundLink>
                <OutboundLink
                    onClick={closeMenu}
                    className={styles.headerLinkInMobileNav}
                    href={createOutboundLinkHref('https://app.mojamatura.edu.pl/auth')}
                    rel='noopener'
                    target='_blank'
                >
                    Logowanie
                </OutboundLink>
            </nav>
            <div className={clsx(common.containerWide, styles.headerContainer)}>
                <Link to={'/'}><LogoFull className={styles.logo}/></Link>

                <Link className={clsx(styles.headerLink, mq.hideBelowS)} to='/#szkoły' onClick={schoolClick}
                      type='button'>
                    Dla szkół
                </Link>
                <Link className={clsx(styles.headerLink, mq.hideBelowS)} to='/#cennik' onClick={priceListClick}
                      type='button'>
                    Cennik
                </Link>
                <OutboundLink
                    className={clsx(styles.headerLink, mq.hideBelowS)}
                    href={createOutboundLinkHref('https://app.mojamatura.edu.pl/auth/register')}
                    rel='noopener'
                    target='_blank'
                >
                    Rejestracja
                </OutboundLink>
                <OutboundLink
                    className={styles.headerLink}
                    href={createOutboundLinkHref('https://app.mojamatura.edu.pl/auth')}
                    rel='noopener'
                    target='_blank'
                >
                    Logowanie
                </OutboundLink>
                <button onClick={openMenu} className={clsx(styles.iconButton, mq.hideOverS)}>
                    <MenuIcon/>
                </button>
            </div>

        </header>
    );
};

export default Header;
