import React, {useEffect, useState} from 'react';

import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import CookiesInfo from '../CookiesInfo';
import Footer from './_components/Footer';
import Header from './_components/Header';
import * as styles from './Layout.module.scss';

const COOKIE_NAME = 'hideCookieInfo';

const Layout = ({ children }) => {
  const [hideCookiesInfo, setHideCookiesInfo] = useState(Cookies.get(COOKIE_NAME));

  const hideCookies = () => {
    setHideCookiesInfo(true);
    Cookies.set(COOKIE_NAME, true);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <meta content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no' name='viewport' />
        <link href='https://fonts.googleapis.com' rel='preconnect' />
        <link crossOrigin href='https://fonts.gstatic.com' rel='preconnect' />
        <link href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap' rel="preload" as="style" />
      </Helmet>
      <Header />
      <main className={styles.main}>{children}</main>
      {!hideCookiesInfo && <CookiesInfo onClose={hideCookies} />}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
