// extracted by mini-css-extract-plugin
export var hideBelowXl = "Footer-module--hideBelowXl--X9PVB";
export var hideOverXl = "Footer-module--hideOverXl--ZWaIF";
export var hideBelowL = "Footer-module--hideBelowL--iYA8W";
export var hideOverL = "Footer-module--hideOverL--dyjOd";
export var hideBelowM = "Footer-module--hideBelowM--XdM09";
export var hideOverM = "Footer-module--hideOverM--dryiU";
export var hideBelowS = "Footer-module--hideBelowS--K7sNt";
export var hideOverS = "Footer-module--hideOverS--NrKlC";
export var hideBelowXs = "Footer-module--hideBelowXs--rlryE";
export var hideOverXs = "Footer-module--hideOverXs--sQHGC";
export var footer = "Footer-module--footer---KpHK";
export var footerContainer = "Footer-module--footerContainer--1d+XR";
export var footerLeft = "Footer-module--footerLeft--C3GoW";
export var newsletter = "Footer-module--newsletter--hi3GC";
export var envelopeIcon = "Footer-module--envelopeIcon--iZAHF";
export var newsletterTitle = "Footer-module--newsletterTitle--e10+N";
export var formWithTitle = "Footer-module--formWithTitle--P4KFG";
export var form = "Footer-module--form--gvfsl";
export var inputName = "Footer-module--inputName--3w0X2";
export var inputEmail = "Footer-module--inputEmail--f2XiH";
export var buttonSubmitContainer = "Footer-module--buttonSubmitContainer--2wMyC";
export var buttonSubmit = "Footer-module--buttonSubmit--pg64K";
export var successMessage = "Footer-module--successMessage--xqHaw";
export var errorMessage = "Footer-module--errorMessage--7T1T6";
export var copyright = "Footer-module--copyright--ANb1t";
export var footerRight = "Footer-module--footerRight--+Qk+t";
export var socialMedia = "Footer-module--socialMedia--0QPp5";
export var facebookIcon = "Footer-module--facebookIcon--k-N-C";
export var likesContainer = "Footer-module--likesContainer--CkHrZ";
export var links = "Footer-module--links--2c9rh";
export var link = "Footer-module--link--PmcwK";