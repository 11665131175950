/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';

import './src/styles/global.module.scss';
import Layout from './src/components/Layout';
import SheetsLayout from "./src/components/SheetsLayout";

export const onClientEntry = () => {
  window.addEventListener("beforeinstallprompt", e => e.preventDefault())
}

export const onServiceWorkerUpdateReady = () => window.location.reload();

/* eslint-disable */
self.addEventListener("install", () => {
  self.skipWaiting()
})

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
if (!("IntersectionObserver" in window)) {
  import(`intersection-observer`)
}

const getSources = () => {
  const SOURCES = {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    LINKTREE: 'linktree',
    GOOGLE: 'google',
    ORGANIC: 'organic',
    EMAIL: 'email',
    TIKTOK: 'tiktok',
  };

  const refererDictionary = {
    'https://l.facebook.com/': SOURCES.FACEBOOK,
    'https://l.instagram.com/': SOURCES.INSTAGRAM,
    'https://linktr.ee/': SOURCES.LINKTREE,
    'https://www.google.com/': SOURCES.GOOGLE,
  };

  const getReferrerDictionary = referrer => refererDictionary[referrer] || SOURCES.ORGANIC

  const { searchParams } = new URL(document.location)
  const source = searchParams.get('source') || getReferrerDictionary(document.referrer)
  const sourceId = searchParams.get('sourceId')
  return { source, sourceId }
}

const { source, sourceId} = getSources();

window.SOURCE = source;
window.SOURCE_ID = sourceId;

export const wrapPageElement = ({ element, props }) => {
  if(props.path.includes('arkusze')) {
    return <Layout {...props}><SheetsLayout {...props}>{element}</SheetsLayout></Layout>
  }
  return <Layout {...props}>{element}</Layout>;
}
